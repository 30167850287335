const messages = {
  ru: {
    commonMailouts: {
      emails: {
        fetchError: "Ошибка получения списка адресов",
      },
      mailouts: {
        success: "Рассылка успешно отправлена",
        error: "Ошибка отправки рассылки",
      },
    },
    courses: {
      emails: {
        fetchError: "Ошибка получения списка адресов",
      },
      mailouts: {
        success: "Рассылка по курсу успешно отправлена",
        error: "Ошибка отправки рассылки по курсу",
      },
    },
    lessons: {
      emails: {
        copied: "Скопировано адресов: %{count}",
        fetchError: "Ошибка получения списка адресов",
      },
      mailouts: {
        success: "Рассылка по занятию успешно отправлена",
        error: "Ошибка отправки рассылки по занятию",
      },
    },
    participants: {
      creation: {
        success: "Ученики были добавлены в участники занятий",
        error: "Ошибка добавления учеников",
        unexpectedError: "Ошибка получения данных с сервера",
      },
    },
  },
}

export default messages
