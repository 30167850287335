import {
  FunctionField,
  NumberField,
  ReferenceArrayField,
  ReferenceField,
  Show,
  SimpleShowLayout,
  SingleFieldList,
  TextField,
} from "react-admin"

export default function FeedbackShow(props) {
  return (
    <Show {...props} title="Отзыв">
      <SimpleShowLayout>
        <TextField source="text" label="Текст" fullWidth />
        <TextField source="author" label="Автор" fullWidth />
        <NumberField
          source="authorAge"
          label="Возраст автора"
          fullWidth
          emptyText="&ndash;"
        />
        <ReferenceField
          label="Курс"
          source="course"
          reference="courses"
          fullWidth
          emptyText="&ndash;"
        >
          <TextField source="title" />
        </ReferenceField>
        <ReferenceArrayField
          label="Авторы курсов"
          source="courseAuthors"
          reference="authors"
          fullWidth
          emptyText="&ndash;"
        >
          <SingleFieldList>
            <FunctionField
              render={(record) => `${record.firstName} ${record.lastName}`}
            />
          </SingleFieldList>
        </ReferenceArrayField>
        <TextField
          source="footer"
          label="Комментарий"
          fullWidth
          emptyText="&ndash;"
        />
      </SimpleShowLayout>
    </Show>
  )
}
