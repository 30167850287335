import {
  List,
  Datagrid,
  TextField,
  Pagination,
  PaginationActions,
} from "react-admin"
import { Edit } from "@material-ui/icons"

import { LinkIconButton, PropsProxy } from "core/components"

export default function PagesContentList(props) {
  const PagesContentActions = (props) => (
    <PaginationActions color="secondary" {...props} />
  )
  const PagesContentPagination = (props) => (
    <Pagination actions={PagesContentActions} {...props} />
  )
  return (
    <List {...props} pagination={<PagesContentPagination />}>
      <Datagrid size="medium" rowClick="edit">
        <TextField source="name" label="Название" />
        <PropsProxy>
          <LinkIconButton to={(record) => `/pages-content/${record.id}`}>
            <Edit fontSize="inherit" />
          </LinkIconButton>
        </PropsProxy>
      </Datagrid>
    </List>
  )
}
