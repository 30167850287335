import { fetchJsonWithAuthJWTToken } from "ra-data-django-rest-framework"

import endpoints from "api/endpoints"

class ParticipantAPI {
  async create(data) {
    const { json } = await fetchJsonWithAuthJWTToken(
      endpoints.participantCreation(),
      {
        method: "post",
        body: JSON.stringify(data),
      }
    )
    return json
  }
}

export default new ParticipantAPI()
