import { convertToRaw } from "draft-js"
import { Create } from "react-admin"
import CourseForm from "./CourseForm"
import { convertToBase64 } from "core/utils/file"

export default function CourseCreate(props) {
  const transform = (data) =>
    convertToBase64(data.image.rawFile).then((base64Image) => ({
      ...data,
      plainTextDescription: data.description.getCurrentContent().getPlainText(),
      description: data.description.getCurrentContent().hasText()
        ? JSON.stringify(convertToRaw(data.description.getCurrentContent()))
        : "",
      image: base64Image,
      prices: data.prices || [],
    }))

  return (
    <Create {...props} title="Новый курс" transform={transform}>
      <CourseForm />
    </Create>
  )
}
