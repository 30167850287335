import React from "react"
import {
  Button,
  Link,
  CreateButton,
  TopToolbar,
  List,
  Pagination,
  PaginationActions,
  Datagrid,
  EmailField,
  FunctionField,
} from "react-admin"
import { Email } from "@material-ui/icons"

function CommonMailoutLink({ record }) {
  return record ? (
    <Button
      component={React.forwardRef((props, ref) => (
        <Link {...props} innerRef={ref} />
      ))}
      to={`/common-mailouts/mailout`}
      label="Рассылка"
    >
      <Email />
    </Button>
  ) : null
}

function CommonMailoutListActions({ basePath, data }) {
  return (
    <TopToolbar>
      <CreateButton basePath={basePath} />
      <CommonMailoutLink record={data} />
    </TopToolbar>
  )
}

export default function CommonMailoutList(props) {
  const MailoutPaginationActions = (props) => (
    <PaginationActions color="secondary" {...props} />
  )
  const MailoutPagination = (props) => (
    <Pagination actions={MailoutPaginationActions} {...props} />
  )
  return (
    <List
      {...props}
      pagination={<MailoutPagination />}
      actions={<CommonMailoutListActions />}
    >
      <Datagrid rowClick="edit" size="medium">
        <EmailField source="email" label="Email" />
        <FunctionField
          render={(record) => `${record.firstName} ${record.lastName}`}
          label="Имя"
        />
      </Datagrid>
    </List>
  )
}
