import {
  List,
  Datagrid,
  TextField,
  Pagination,
  PaginationActions,
} from "react-admin"
import { Edit } from "@material-ui/icons"

import { LinkIconButton, PropsProxy } from "core/components"

export default function LibraryItemList(props) {
  const LibraryItemPaginationActions = (props) => (
    <PaginationActions color="secondary" {...props} />
  )

  const LibraryItemPagination = (props) => (
    <Pagination actions={LibraryItemPaginationActions} {...props} />
  )
  return (
    <List {...props} pagination={<LibraryItemPagination />} title="Библиотека">
      <Datagrid rowClick="show">
        <TextField source="author" label="Автор" emptyText="&mdash;" />
        <TextField source="title" label="Название" />
        <PropsProxy>
          <LinkIconButton to={(record) => `/library-items/${record.id}`}>
            <Edit fontSize="inherit" />
          </LinkIconButton>
        </PropsProxy>
      </Datagrid>
    </List>
  )
}
