export const COURSE_STATE_DRAFT = "draft"
export const COURSE_STATE_ANNOUNCED = "announced"
export const COURSE_STATE_CAN_BE_SUBSCRIBED = "can_be_subscribed"

export const courseStateOptions = {
  [COURSE_STATE_DRAFT]: "Черновик",
  [COURSE_STATE_ANNOUNCED]: "Анонсирован",
  [COURSE_STATE_CAN_BE_SUBSCRIBED]: "Открыта запись",
}

export const CURRENCY_CHOICE_RUB = "CURRENCY_CHOICE_RUB"
export const CURRENCY_CHOICE_EURO = "CURRENCY_CHOICE_EURO"
export const CURRENCY_CHOICE_USD = "CURRENCY_CHOICE_USD"

export const currencyChoiceOptions = {
  [CURRENCY_CHOICE_RUB]: "₽",
  [CURRENCY_CHOICE_EURO]: "€",
}
