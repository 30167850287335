import {
  Datagrid,
  List,
  ReferenceField,
  TextField,
  Pagination,
  PaginationActions,
} from "react-admin"
import { Edit } from "@material-ui/icons"

import { LinkIconButton, PropsProxy } from "core/components"

export default function FeedbackList(props) {
  const FeedbackPaginationActions = (props) => (
    <PaginationActions color="secondary" {...props} />
  )
  const FeedbackPagination = (props) => (
    <Pagination actions={FeedbackPaginationActions} {...props} />
  )
  return (
    <List {...props} pagination={<FeedbackPagination />}>
      <Datagrid rowClick="show" size="medium">
        <TextField source="author" label="Автор" />
        <ReferenceField label="Курс" source="course" reference="courses">
          <TextField source="title" />
        </ReferenceField>
        <PropsProxy>
          <LinkIconButton to={(record) => `/feedback/${record.id}`}>
            <Edit fontSize="inherit" />
          </LinkIconButton>
        </PropsProxy>
      </Datagrid>
    </List>
  )
}
