import {
  List,
  Datagrid,
  ReferenceField,
  TextField,
  Pagination,
  PaginationActions,
} from "react-admin"
import { Edit } from "@material-ui/icons"

import { LinkIconButton, PropsProxy } from "core/components"

export default function QuotationList(props) {
  const QuotationPaginationActions = (props) => (
    <PaginationActions color="secondary" {...props} />
  )
  const QuotationPagination = (props) => (
    <Pagination actions={QuotationPaginationActions} {...props} />
  )
  return (
    <List {...props} pagination={<QuotationPagination />}>
      <Datagrid size="medium" rowClick="show">
        <TextField source="source" label="Источник" />
        <ReferenceField reference="courses" source="course" label="Курс">
          <TextField source="title" />
        </ReferenceField>
        <PropsProxy>
          <LinkIconButton to={(record) => `/quotations/${record.id}`}>
            <Edit fontSize="inherit" />
          </LinkIconButton>
        </PropsProxy>
      </Datagrid>
    </List>
  )
}
