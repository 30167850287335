import { convertToRaw } from "draft-js"
import { useState } from "react"
import { useDispatch } from "react-redux"
import {
  BooleanInput,
  FormWithRedirect,
  FormDataConsumer,
  Confirm,
  required,
  TextInput,
  Toolbar,
  fetchStart,
  fetchEnd,
  useRedirect,
  useNotify,
} from "react-admin"
import { Button } from "@material-ui/core"

import CommonMailoutsAPI from "api/common-mailouts"
import MailoutsAPI from "api/mailouts"
import { Editor } from "core/components"
import { convertToHtml } from "core/utils/editor"
import { formatTextByNumber } from "core/utils/text"

function CommonMailoutForm(props) {
  const { lessonId } = props.match.params
  const [loading, setLoading] = useState(false)
  const [emails, setEmails] = useState([])
  const [dialogOpen, setDialogOpen] = useState(false)
  const redirect = useRedirect()
  const dispatch = useDispatch()
  const notify = useNotify()

  const getEmails = (event, commaSeparated) => {
    event.preventDefault()
    if (commaSeparated) {
      setEmails(
        commaSeparated
          .split(new RegExp("[;,]"))
          .map((i) => i.trim().toLowerCase())
      )
      setDialogOpen(true)
    } else {
      setLoading(true)
      dispatch(fetchStart())
      CommonMailoutsAPI.mailoutEmails(lessonId)
        .then(({ emails: fetchedEmails }) => {
          setEmails(fetchedEmails)
          setDialogOpen(true)
        })
        .catch(() => {
          notify("commonMailouts.emails.fetchError", "error")
          setDialogOpen(false)
        })
        .finally(() => {
          setLoading(false)
          dispatch(fetchEnd())
        })
    }
  }

  const handleSending = (event, data) => {
    event.preventDefault()
    setDialogOpen(false)
    dispatch(fetchStart())
    setLoading(true)
    MailoutsAPI.sendEmail(data)
      .then(() => {
        notify("commonMailouts.mailouts.success")
        redirect(`/common-mailouts`)
      })
      .catch(() => {
        notify("commonMailouts.mailouts.error")
      })
      .finally(() => {
        setLoading(false)
        dispatch(fetchEnd())
      })
  }

  return (
    <FormWithRedirect
      render={() => (
        <>
          <TextInput
            source="subject"
            label="Тема"
            fullWidth
            validate={required()}
            variant="standard"
          />
          <BooleanInput source="send_to_all" label="Отправить всем" />
          <FormDataConsumer>
            {({ formData }) =>
              formData.send_to_all ? null : (
                <TextInput
                  source="emails"
                  label="Получатели"
                  validate={required()}
                  fullWidth
                  variant="standard"
                />
              )
            }
          </FormDataConsumer>
          <Editor source="text" label="Текст" />
          <Toolbar>
            <FormDataConsumer>
              {({ formData }) => (
                <Button
                  type="submit"
                  variant="contained"
                  disabled={loading}
                  onClick={(event) => getEmails(event, formData.emails)}
                >
                  Отправить
                </Button>
              )}
            </FormDataConsumer>
          </Toolbar>
          <FormDataConsumer>
            {({ formData }) => (
              <Confirm
                isOpen={dialogOpen}
                loading={loading}
                title=""
                content={`Вы действительно хотите сделать рассылку на ${formatTextByNumber(
                  emails.length,
                  "адрес",
                  "адресов",
                  "адреса"
                )}?`}
                onConfirm={(event) =>
                  handleSending(event, {
                    subject: formData.subject,
                    recipients: emails.join(","),
                    text: formData.text.getCurrentContent().getPlainText(),
                    html: convertToHtml(
                      JSON.stringify(
                        convertToRaw(formData.text.getCurrentContent())
                      )
                    ),
                  })
                }
                onClose={() => setDialogOpen(false)}
              />
            )}
          </FormDataConsumer>
        </>
      )}
    />
  )
}

export default CommonMailoutForm
