import { fetchJsonWithAuthJWTToken } from "ra-data-django-rest-framework"

import endpoints from "api/endpoints"

class CommonMailoutsAPI {
  mailoutEmails() {
    return fetchJsonWithAuthJWTToken(endpoints.commonMailoutEmails()).then(
      ({ json }) => json
    )
  }
}

export default new CommonMailoutsAPI()
