import CommonMailoutCreate from "./CommonMailoutCreate"
import CommonMailoutEdit from "./CommonMailoutEdit"
import CommonMailoutList from "./CommonMailoutList"
import CommonMailoutShow from "./CommonMailoutShow"

const CommonMailoutCRUD = {
  create: CommonMailoutCreate,
  edit: CommonMailoutEdit,
  list: CommonMailoutList,
  show: CommonMailoutShow,
}

export default CommonMailoutCRUD
