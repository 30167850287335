import { fetchJsonWithAuthJWTToken } from "ra-data-django-rest-framework"

import endpoints from "api/endpoints"

class LessonsAPI {
  async mailoutEmails(id) {
    const { json } = await fetchJsonWithAuthJWTToken(
      endpoints.lessonMailoutEmails(id)
    )
    return json
  }
}

export default new LessonsAPI()
