import QuotationCreate from "./QuotationCreate"
import QuotationEdit from "./QuotationEdit"
import QuotationList from "./QuotationList"
import QuotationShow from "./QuotationShow"

const QuotationCRUD = {
  create: QuotationCreate,
  edit: QuotationEdit,
  list: QuotationList,
  show: QuotationShow,
}

export default QuotationCRUD
