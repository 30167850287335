import DiscipleList from "./DiscipleList"
import DiscipleShow from "./DiscipleShow"

const DiscipleCRUD = {
  list: DiscipleList,
  show: DiscipleShow,
}

export default DiscipleCRUD

