import CourseCreate from "./CourseCreate"
import CourseEdit from "./CourseEdit"
import CourseList from "./CourseList"
import CourseShow from "./CourseShow"

const CourseCRUD = {
  create: CourseCreate,
  edit: CourseEdit,
  list: CourseList,
  show: CourseShow,
}

export default CourseCRUD
