import { Datagrid, FunctionField, List } from "react-admin"

export default function AuthorList(props) {
  return (
    <List {...props}>
      <Datagrid rowClick="show">
        <FunctionField
          label="Имя"
          render={(record) => `${record.firstName} ${record.lastName}`}
        />
      </Datagrid>
    </List>
  )
}
