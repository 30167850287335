import { convertToRaw } from "draft-js"
import { useState } from "react"
import { useDispatch } from "react-redux"
import {
  FormWithRedirect,
  FormDataConsumer,
  Confirm,
  required,
  TextInput,
  Toolbar,
  fetchStart,
  fetchEnd,
  useRedirect,
  useNotify,
} from "react-admin"
import { Button } from "@material-ui/core"

import LessonsApi from "api/lessons"
import MailoutsAPI from "api/mailouts"
import { Editor } from "core/components"
import { convertToHtml } from "core/utils/editor"
import { formatTextByNumber } from "core/utils/text"

function LessonMailoutForm(props) {
  const { lessonId } = props.match.params
  const [loading, setLoading] = useState(false)
  const [emails, setEmails] = useState([])
  const [dialogOpen, setDialogOpen] = useState(false)
  const redirect = useRedirect()
  const dispatch = useDispatch()
  const notify = useNotify()

  const getEmails = (event) => {
    event.preventDefault()
    setLoading(true)
    dispatch(fetchStart())
    LessonsApi.mailoutEmails(lessonId)
      .then(({ emails: fetchedEmails }) => {
        setEmails(fetchedEmails)
        setDialogOpen(true)
      })
      .catch(() => {
        notify("lessons.emails.fetchError", "error")
        setDialogOpen(false)
      })
      .finally(() => {
        setLoading(false)
        dispatch(fetchEnd())
      })
  }

  const handleSending = (event, data) => {
    event.preventDefault()
    setDialogOpen(false)
    dispatch(fetchStart())
    setLoading(true)
    MailoutsAPI.sendEmail(data)
      .then(() => {
        notify("lessons.mailouts.success")
        redirect(`/lessons/${lessonId}/show`)
      })
      .catch(() => {
        notify("lessons.mailouts.error", "error")
      })
      .finally(() => {
        setLoading(false)
        dispatch(fetchEnd())
      })
  }

  return (
    <FormWithRedirect
      render={() => (
        <>
          <TextInput
            source="subject"
            label="Тема"
            fullWidth
            validate={required()}
            variant="standard"
          />
          <Editor source="text" label="Текст" />
          <Toolbar>
            <Button
              type="submit"
              variant="contained"
              disabled={loading}
              onClick={getEmails}
            >
              Отправить
            </Button>
          </Toolbar>
          <FormDataConsumer>
            {({ formData }) => (
              <Confirm
                isOpen={dialogOpen}
                loading={loading}
                title=""
                content={`Вы действительно хотите сделать рассылку на ${formatTextByNumber(
                  emails.length,
                  "адрес",
                  "адресов",
                  "адреса"
                )}?`}
                onConfirm={(event) =>
                  handleSending(event, {
                    subject: formData.subject,
                    recipients: emails.join(","),
                    text: formData.text.getCurrentContent().getPlainText(),
                    html: convertToHtml(
                      JSON.stringify(
                        convertToRaw(formData.text.getCurrentContent())
                      )
                    ),
                  })
                }
                onClose={() => setDialogOpen(false)}
              />
            )}
          </FormDataConsumer>
        </>
      )}
    />
  )
}

export default LessonMailoutForm
