import {
  Create,
  SimpleForm,
  ReferenceInput,
  SelectInput,
  TextInput,
} from "react-admin"

export default function QuotationCreate(props) {
  return (
    <Create {...props} title="Новая цитата">
      <SimpleForm>
        <ReferenceInput
          label="Курс"
          reference="courses"
          source="course"
          perPage={200}
          sort={{ field: "title", order: "ASC" }}
          fullWidth
          variant="standard"
        >
          <SelectInput optionText="title" />
        </ReferenceInput>
        <TextInput
          source="source"
          label="Источник"
          fullWidth
          variant="standard"
        />
        <TextInput
          source="text"
          label="Текст"
          multiline
          fullWidth
          variant="standard"
        />
      </SimpleForm>
    </Create>
  )
}
