import { useState } from "react"
import {
  ArrayInput,
  SimpleFormIterator,
  BooleanInput,
  DateInput,
  ImageField,
  ImageInput,
  NumberInput,
  SelectInput,
  TextInput,
  FormWithRedirect,
  required,
  Toolbar,
} from "react-admin"

import { Grid, Tab, Tabs, Divider, makeStyles } from "@material-ui/core"
import { Editor } from "core/components"
import { discipleAgeOptions } from "disciples/constants"
import { courseStateOptions, currencyChoiceOptions } from "./constants"

const useStyles = makeStyles({
  root: {
    width: "95%",
    overflowX: "hidden",
    overflowY: "hidden",
    margin: "1em auto 0 auto",
  },

  tabPanel: {
    margin: "8px 16px 0 16px",
  },

  booleanMargin: {
    marginTop: 16,
  },
})

function TabPanel(props) {
  const { children, value, index, ...rest } = props
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...rest}
    >
      {value === index && children}
    </div>
  )
}

function formatImage(value) {
  if (!value || typeof value === "string") {
    // Value is null or the url string from the backend, wrap it in an object so the form input can handle it
    return { url: value }
  } else {
    // Else a new image is selected which results in a value object already having a preview link under the url key
    return value
  }
}

export default function CourseForm(props) {
  const classes = useStyles()

  const [currentTab, setCurrentTab] = useState(0)
  const handleChange = (_, newValue) => {
    setCurrentTab(newValue)
  }
  return (
    <FormWithRedirect
      {...{ ...props, sanitizeEmptyValues: false }}
      initialValues={() => ({ lessonsBlockSize: 1 })}
      render={(formProps) => (
        <>
          <Tabs
            value={currentTab}
            onChange={handleChange}
            indicatorColor="primary"
          >
            <Tab label="Описание" />
            <Tab label="Стоимость" />
          </Tabs>
          <Divider />
          <TabPanel value={currentTab} index={0} className={classes.tabPanel}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12}>
                <TextInput
                  source="title"
                  label="Название"
                  fullWidth
                  validate={required()}
                  variant="standard"
                />
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  source="subtitle"
                  label="Подзаголовок"
                  fullWidth
                  variant="standard"
                  parse={(value) => value}
                />
              </Grid>
              <Grid item xs={12}>
                <Editor source="description" label="Описание" />
              </Grid>
              <Grid item xs={12} lg={4}>
                <DateInput
                  label="Начало курса"
                  options={{
                    format: "DD.MM.YYYY",
                  }}
                  source="start"
                  variant="standard"
                  fullWidth
                  validate={required()}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <SelectInput
                  source="disciplesAge"
                  label="Возраст участников"
                  choices={Object.keys(discipleAgeOptions).map((option) => ({
                    id: option,
                    name: discipleAgeOptions[option],
                  }))}
                  variant="standard"
                  validate={required()}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <BooleanInput
                  source="fullYear"
                  label="Годичный курс"
                  variant="standard"
                  fullWidth
                  helperText={null}
                  className={classes.booleanMargin}
                  defaultValue={false}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <SelectInput
                  source="state"
                  label="Статус"
                  choices={Object.keys(courseStateOptions).map((state) => ({
                    id: state,
                    name: courseStateOptions[state],
                  }))}
                  variant="standard"
                  fullWidth
                  validate={required()}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <NumberInput
                  source="participantsLimit"
                  label="Максимальное число участников"
                  variant="standard"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <NumberInput
                  source="lessonsBlockSize"
                  label="Размер блока"
                  min={1}
                  variant="standard"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <ImageInput
                  format={formatImage}
                  source="image"
                  label="Картинка"
                  accept="image/*"
                  validate={required()}
                >
                  <ImageField source="url" />
                </ImageInput>
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={currentTab} index={1} className={classes.tabPanel}>
            <ArrayInput source="prices" label="Цены на курс">
              <SimpleFormIterator disableReordering>
                <SelectInput
                  source="currency"
                  label="Валюта"
                  choices={Object.keys(currencyChoiceOptions).map(
                    (currency) => ({
                      id: currency,
                      name: currencyChoiceOptions[currency],
                    })
                  )}
                  fullWidth
                  variant="standard"
                />
                <NumberInput
                  source="coursePrice"
                  label="Стоимость курса"
                  helperText="Если пользователь оплачивает сразу все занятия"
                  fullWidth
                  min={0}
                  variant="standard"
                />
                <NumberInput
                  source="lessonPrice"
                  label="Стоимость занятия"
                  helperText="Если пользователь покупает до 5 занятий"
                  fullWidth
                  min={0}
                  variant="standard"
                />
                <NumberInput
                  source="lessonWholesalePrice"
                  label="Группа занятий"
                  helperText="Если пользователь покупает 5 и более занятий"
                  fullWidth
                  min={0}
                  variant="standard"
                />
              </SimpleFormIterator>
            </ArrayInput>
          </TabPanel>
          <Toolbar
            basePath={formProps.basePath}
            handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
            handleSubmit={formProps.handleSubmit}
            invalid={formProps.invalid}
            record={formProps.record}
            saving={formProps.saving}
            validating={formProps.validating}
          />
        </>
      )}
    />
  )
}
