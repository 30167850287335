import apiUrl from "core/apiUrl"
const endpoints = {
  //courses
  coursesList: () => `${apiUrl}/v2/admin/courses/`,

  // lessons
  lessonList: () => `${apiUrl}/v2/admin/lessons/`,

  // disciples
  disciplesList: () => `${apiUrl}/v2/admin/disciples/`,

  // mailouts
  courseMailoutEmails: (id) =>
    `${apiUrl}/v2/admin/courses/${id}/mailout-emails/`,
  commonMailoutEmails: () => `${apiUrl}/v2/admin/common-mailouts/emails/`,
  lessonMailoutEmails: (id) =>
    `${apiUrl}/v2/admin/lessons/${id}/mailout-emails/`,
  sendMailout: () => `${apiUrl}/v2/admin/mailout/`,

  // participants
  participantCreation: () => `${apiUrl}/v2/admin/participants/`,
  participantList: () => `{apiUrl}/v2/admin/participants/`,
}

export default endpoints
