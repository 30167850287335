import PropTypes from "prop-types"
import { EditView, EditContextProvider, useEditController } from "react-admin"

export default function TransformedEdit({ transformRecord, ...restProps }) {
  const { record, ...rest } = useEditController(restProps)
  const controllerProps = {
    record: transformRecord ? transformRecord(record) : record,
    ...rest,
  }
  return (
    <EditContextProvider value={controllerProps}>
      <EditView {...restProps} {...controllerProps} />
    </EditContextProvider>
  )
}

TransformedEdit.propTypes = {
  transformRecord: PropTypes.func,
}
