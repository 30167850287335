import {
  Button,
  EditButton,
  FunctionField,
  Show,
  SimpleShowLayout,
  TextField,
  TopToolbar,
  ListButton,
  Link,
} from "react-admin"
import { Email, FormatAlignJustify, HowToReg } from "@material-ui/icons"

import { convertToHtml } from "core/utils/editor"

function createMarkup(html) {
  return { __html: html }
}

function CourseParticipantsLink({ record }) {
  return record ? (
    <ListButton
      color="primary"
      component={Link}
      icon={<HowToReg />}
      to={{
        pathname: "/participants",
        search: `filter=${JSON.stringify({ course: record.id })}`,
      }}
      label="Участники"
    />
  ) : null
}

function CourseLessonsLink({ record }) {
  return record ? (
    <ListButton
      color="primary"
      component={Link}
      icon={<FormatAlignJustify />}
      to={{
        pathname: "/lessons",
        search: `filter=${JSON.stringify({
          course: record.id,
        })}&sort=number&order=ASC`,
      }}
      label="Занятия"
    />
  ) : null
}

function CourseMailoutLink({ record }) {
  return record ? (
    <Button
      component={Link}
      to={`/courses/${record.id}/mailout`}
      label="Рассылка"
    >
      <Email />
    </Button>
  ) : null
}

function CourseShowActions({ basePath, data }) {
  return (
    <TopToolbar>
      <EditButton basePath={basePath} record={data} />
      <CourseLessonsLink record={data} />
      <CourseParticipantsLink record={data} />
      <CourseMailoutLink record={data} />
    </TopToolbar>
  )
}

export default function CourseShow(props) {
  return (
    <Show {...props} title="Курс" actions={<CourseShowActions />}>
      <SimpleShowLayout>
        <TextField source="title" label="Название" />
        <TextField source="subtitle" label="Подзаголовок" emptyText="&mdash;" />
        <FunctionField
          label="Описание"
          render={(record) => (
            <div
              dangerouslySetInnerHTML={createMarkup(
                convertToHtml(record.description)
              )}
            />
          )}
        />
      </SimpleShowLayout>
    </Show>
  )
}
