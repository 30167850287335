import { Create, SimpleForm, TextInput } from "react-admin"

export default function LibraryCategoryCreate(props) {
  return (
    <Create {...props} title="Новая категория">
      <SimpleForm variant="standard">
        <TextInput source="name" label="Название" />
      </SimpleForm>
    </Create>
  )
}
