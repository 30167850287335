import React from "react"

export default function PropsProxy({ children, ...rest }) {
  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { ...rest })
    } else {
      return child
    }
  })

  return <>{childrenWithProps}</>
}
