import {
  Show,
  SimpleShowLayout,
  TextField,
  EmailField,
  FunctionField,
  useShowController,
} from "react-admin"

export default function DiscipleShow(props) {
  const { record } = useShowController(props)
  const isAdultProfile =
    record &&
    record.yearOfBirth &&
    new Date().getFullYear() - record.yearOfBirth >= 18
  return (
    <Show {...props} title="Информация об участнике">
      <SimpleShowLayout>
        <TextField source="firstName" label="Имя" />
        <TextField source="lastName" label="Фамилия" />
        <EmailField source="email" label="Email" />
        <FunctionField
          label="Год рождения"
          emptyText="&mdash;"
          render={({ yearOfBirth }) => {
            return yearOfBirth || `раньше ${new Date().getFullYear() - 18}го`
          }}
        />
        <TextField
          source="profile.country"
          label="Страна проживания"
          emptyText="Не указано"
        />
        <TextField
          source="profile.mainLanguage"
          label="Основной язык общения"
          emptyText="Не указано"
        />
        {isAdultProfile ? null : (
          <FunctionField
            label="Ходит ли слушатель в школу"
            emptyText="Не указано"
            render={({ profile }) => {
              if (!profile || profile.visitSchool === null) {
                return "Не указано"
              } else {
                return profile.visitSchool ? "Да" : "Нет"
              }
            }}
          />
        )}
        <TextField
          source="profile.timezone"
          label="Часовой пояс"
          emptyText="Не указано"
        />
        {isAdultProfile ? null : (
          <TextField
            source="profile.readWriteProblems"
            label="Проблемы с чтением и письмом"
            emptyText="Не указано"
          />
        )}
        <TextField
          source="profile.otherInfo"
          label="Другая важная информация"
          emptyText="Не указано"
        />
      </SimpleShowLayout>
    </Show>
  )
}
