import {
  Create,
  TextInput,
  ReferenceArrayInput,
  ReferenceInput,
  required,
  SimpleForm,
  SelectArrayInput,
  SelectInput,
} from "react-admin"

export default function FeedbackCreate(props) {
  return (
    <Create {...props} title="Новый отзыв">
      <SimpleForm>
        <TextInput
          source="text"
          label="Текст"
          fullWidth
          validate={required()}
          multiline
          variant="standard"
        />
        <TextInput
          source="author"
          label="Автор"
          fullWidth
          validate={required()}
          variant="standard"
        />
        <TextInput
          source="authorAge"
          label="Возраст автора"
          fullWidth
          variant="standard"
        />
        <ReferenceInput
          label="Курс"
          source="course"
          reference="courses"
          perPage={200}
          sort={{ field: "title", order: "ASC" }}
          fullWidth
          variant="standard"
        >
          <SelectInput optionText="title" />
        </ReferenceInput>
        <ReferenceArrayInput
          label="Авторы курсов"
          source="courseAuthors"
          reference="authors"
          fullWidth
          variant="standard"
        >
          <SelectArrayInput
            optionText={(record) => `${record.firstName} ${record.lastName}`}
          />
        </ReferenceArrayInput>
        <TextInput
          source="footer"
          label="Комментарий"
          fullWidth
          multiline
          variant="standard"
        />
      </SimpleForm>
    </Create>
  )
}
