import { EmailField, Show, SimpleShowLayout, TextField } from "react-admin"

export default function AuthorShow(props) {
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField source="firstName" label="Имя" />
        <TextField source="lastName" label="Фамилия" />
        <EmailField source="email" />
      </SimpleShowLayout>
    </Show>
  )
}
