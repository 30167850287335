import React from "react"
import PropTypes from "prop-types"
import { IconButton } from "@material-ui/core"
import { Link } from "react-admin"

export default function LinkIconButton({ children, to, record, ...rest }) {
  return (
    <IconButton
      component={React.forwardRef((props, ref) => (
        <Link {...props} innerRef={ref} />
      ))}
      to={record ? to(record) : ""}
      onClick={(e) => e.stopPropagation()}
      size="small"
      color="primary"
    >
      {children}
    </IconButton>
  )
}

LinkIconButton.propTypes = {
  to: PropTypes.func.isRequired,
  record: PropTypes.object,
}
