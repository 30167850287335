import React from "react"
import { convertToRaw } from "draft-js"
import { convertFromRaw, EditorState } from "draft-js"
import { TransformedEdit } from "core/components"
import CourseForm from "./CourseForm"
import { convertToBase64 } from "core/utils/file"

export default function CourseEdit(props) {
  const transformRecord = (record) =>
    record
      ? {
          ...record,
          description: EditorState.createWithContent(
            convertFromRaw(JSON.parse(record["description"]))
          ),
        }
      : record

  const transform = (data) => {
    const sendData = {
      ...data,
      plainTextDescription: data.description.getCurrentContent().getPlainText(),
      description: data.description.getCurrentContent().hasText()
        ? JSON.stringify(convertToRaw(data.description.getCurrentContent()))
        : "",
      prices: data.prices || [],
    }
    console.log(sendData)
    return convertToBase64(data.image.rawFile).then(
      (base64Image) => ({
        ...sendData,
        image: base64Image,
      }),
      () => sendData
    )
  }

  return (
    <TransformedEdit
      transformRecord={transformRecord}
      title="Редактирование курса"
      transform={transform}
      {...props}
    >
      <CourseForm />
    </TransformedEdit>
  )
}
