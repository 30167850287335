import { Show, SimpleShowLayout, TextField, EmailField } from "react-admin"

export default function CommonMailoutShow(props) {
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <EmailField source="email" label="Email" />
        <TextField source="firstName" label="Имя" />
        <TextField source="lastName" label="Фамилия" />
      </SimpleShowLayout>
    </Show>
  )
}
