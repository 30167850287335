import { Route } from "react-router-dom"
import CommonMailoutForm from "common-mailouts/CommonMailoutForm"
import CourseMailoutForm from "courses/CourseMailoutForm"
import LessonMailoutForm from "lessons/LessonMailoutForm"

const customRoutes = [
  <Route exact path="/common-mailouts/mailout" component={CommonMailoutForm} />,
  <Route
    exact
    path="/courses/:courseId/mailout"
    component={CourseMailoutForm}
  />,
  <Route
    exact
    path="/lessons/:lessonId/mailout"
    component={LessonMailoutForm}
  />,
]

export default customRoutes
