import { Admin, Resource } from "react-admin"
import drfProvider, {
  jwtTokenAuthProvider,
  fetchJsonWithAuthJWTToken,
} from "ra-data-django-rest-framework"
import russianMessages from "ra-language-russian"
import domainMessages from "messages"
import polyglotI18nProvider from "ra-i18n-polyglot"

import apiUrl from "core/apiUrl"
import authorCRUD from "authors"
import commonMailoutCRUD from "common-mailouts"
import customRoutes from "customRoutes"
import courseCRUD from "courses"
import DiscipleCRUD from "disciples"
import feedbackCRUD from "feedback"
import Layout from "layout/Layout"
import lessonCRUD from "lessons"
import { libraryCategoryCRUD, libraryItemCRUD } from "library"
import PagesContentCRUD from "pages-content"
import ParticipantCRUD from "participants"
import QuotationCRUD from "quotations"
import vagantesTheme from "theme"

function App() {
  const dataProvider = drfProvider(
    `${apiUrl}/v2/admin`,
    fetchJsonWithAuthJWTToken
  )
  const authProvider = jwtTokenAuthProvider({
    obtainAuthTokenUrl: `${apiUrl}/v2/token/`,
  })
  const i18nProvider = polyglotI18nProvider(
    () => ({ ...russianMessages, ...domainMessages.ru }),
    "ru"
  )
  return (
    <Admin
      title=""
      dataProvider={dataProvider}
      authProvider={authProvider}
      layout={Layout}
      i18nProvider={i18nProvider}
      theme={vagantesTheme}
      customRoutes={customRoutes}
    >
      <Resource name="courses" options={{ label: "Курсы" }} {...courseCRUD} />
      <Resource name="authors" options={{ label: "Авторы" }} {...authorCRUD} />
      <Resource
        name="common-mailouts"
        options={{ label: "Рассылки" }}
        {...commonMailoutCRUD}
      />
      <Resource name="course-prices" options={{ label: "Стоимость курсов" }} />
      <Resource
        name="feedback"
        options={{ label: "Отзывы " }}
        {...feedbackCRUD}
      />
      <Resource name="disciples" options={{ label: "Пользователи" }}
        {...DiscipleCRUD} />
      <Resource name="lessons" options={{ label: "Курсы" }} {...lessonCRUD} />
      <Resource
        name="participants"
        options={{ label: "Участники" }}
        {...ParticipantCRUD}
      />
      <Resource
        name="quotations"
        options={{ label: "Цитаты" }}
        {...QuotationCRUD}
      />
      <Resource
        name="library-categories"
        options={{ label: "Категории библиотеки" }}
        {...libraryCategoryCRUD}
      />
      <Resource
        name="library-items"
        options={{ label: "Библиотека" }}
        {...libraryItemCRUD}
      />
      <Resource
        name="pages-content"
        options={{ label: "Содержимое страниц" }}
        {...PagesContentCRUD}
      />
    </Admin>
  )
}

export default App
