import { EditorState, convertFromRaw, convertToRaw } from "draft-js"
import draftToHtml from "draftjs-to-html"

export function convertToPlainText(jsonString) {
  try {
    return EditorState.createWithContent(convertFromRaw(JSON.parse(jsonString)))
      .getCurrentContent()
      .getPlainText()
  } catch {
    return jsonString
  }
}

export function ensureValidEditorContent(content) {
  try {
    return EditorState.createWithContent(convertFromRaw(JSON.parse(content)))
  } catch {
    const validContent = {
      blocks: [
        {
          key: "qrel",
          text: content.replace(/[\n\r]+/g, " "),
          type: "unstyled",
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [],
          data: {},
        },
      ],
      entityMap: {},
    }
    return EditorState.createWithContent(convertFromRaw(validContent))
  }
}

export function convertToHtml(jsonString) {
  return draftToHtml(convertToRaw(convertFromRaw(JSON.parse(jsonString))))
}
