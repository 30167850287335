import LessonCreate from "./LessonCreate"
import LessonEdit from "./LessonEdit"
import LessonList from "./LessonList"
import LessonShow from "./LessonShow"

const LessonCRUD = {
  create: LessonCreate,
  edit: LessonEdit,
  list: LessonList,
  show: LessonShow,
}

export default LessonCRUD
