import { convertToRaw } from "draft-js"
import { SimpleForm, required, TextInput } from "react-admin"
import { Editor, TransformedEdit } from "core/components"
import { ensureValidEditorContent } from "core/utils/editor"

export default function PagesContentEdit(props) {
  const trasformRecord = (record) =>
    record
      ? {
          ...record,
          text: ensureValidEditorContent(record.text),
        }
      : record
  const transform = ({ text, ...rest }) => ({
    ...rest,
    text:
      text && text.getCurrentContent().hasText()
        ? JSON.stringify(convertToRaw(text.getCurrentContent()))
        : "",
  })
  return (
    <TransformedEdit
      title="Редактирование содержимого страниц"
      transformRecord={trasformRecord}
      transform={transform}
      {...props}
    >
      <SimpleForm variant="standard">
        <TextInput
          source="name"
          label="Название"
          fullWidth
          validate={required()}
        />
        <Editor source="text" label="Текст" />
      </SimpleForm>
    </TransformedEdit>
  )
}
