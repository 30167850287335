import {
  List,
  Datagrid,
  TextField,
  Pagination,
  PaginationActions,
} from "react-admin"
import { Edit } from "@material-ui/icons"

import { LinkIconButton, PropsProxy } from "core/components"

export default function LibraryCategoryList(props) {
  const LibraryCategoryPaginationActions = (props) => (
    <PaginationActions color="secondary" {...props} />
  )

  const LibraryCategoryPagination = (props) => (
    <Pagination actions={LibraryCategoryPaginationActions} {...props} />
  )
  return (
    <List
      {...props}
      pagination={<LibraryCategoryPagination />}
      title="Библиотека"
    >
      <Datagrid rowClick="edit">
        <TextField source="name" label="Название" />
        <PropsProxy>
          <LinkIconButton to={(record) => `/library-categories/${record.id}`}>
            <Edit fontSize="inherit" />
          </LinkIconButton>
        </PropsProxy>
      </Datagrid>
    </List>
  )
}
