import { useInput } from "react-admin"
import { Editor as WysiwygEditor } from "react-draft-wysiwyg"
import {
  makeStyles,
  FormControl,
  InputLabel,
  FormHelperText,
} from "@material-ui/core"

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import React from "react"

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: "0.84rem",
    position: "inherit",
    transform: "none",
    paddingBottom: theme.spacing(0.7),
  },

  editor: {
    "& .editor-toolbar": {
      backgroundColor: "#fff",
      border: `1px solid ${theme.colors.lightBorder}`,
      fontFamily: '"Spectral", serif',
      borderRadius: "0.25rem",
    },

    "& .editor-wrapper": {
      border: `1px solid ${theme.colors.lightBorder}`,
      cursor: "text",
      padding: theme.spacing(1),
      minHeight: 300,
    },

    "& .RichEditor-editor .RichEditor-blockquote": {
      borderLeft: "5px solid #eee",
      color: "#555",
      fontStyle: "italic",
      margin: theme.spacing(2, 0),
      padding: theme.spacing(1, 2),
    },

    "&.error": {
      border: `2px solid ${theme.palette.error.main}`,
    },
  },
}))

export default function Editor({ source, ...rest }) {
  const classes = useStyles()
  const {
    input: { onChange, value },
    meta: { error },
  } = useInput({ source })
  return (
    <FormControl error={error} fullWidth>
      <InputLabel
        className={classes.label}
        htmlFor={`${source}_input`}
        disableAnimation
      >
        {rest["label"] || source}
      </InputLabel>
      <div className={`${classes.editor} ${error ? "error" : ""}`}>
        <WysiwygEditor
          editorState={value}
          onEditorStateChange={onChange}
          toolbarClassName="editor-toolbar"
          wrapperClassName="editor-wrapper"
          editorClassName="editor"
          stripPastedStyles
          invalid={!!error}
          toolbar={{
            options: [
              "inline",
              "blockType",
              "list",
              "textAlign",
              "link",
              "remove",
              "history",
            ],
          }}
          {...rest}
        />
      </div>
      {error ? (
        <FormHelperText>{source["helperText"] || ""}</FormHelperText>
      ) : null}
    </FormControl>
  )
}
