import { useCallback, useState } from "react"
import {
  Button,
  List,
  Datagrid,
  ReferenceField,
  DateField,
  SelectField,
  EmailField,
  FunctionField,
  FilterButton,
  ReferenceInput,
  SelectInput,
  TextField,
  TopToolbar,
  useDataProvider,
  useListContext,
  useNotify,
} from "react-admin"
import { GetApp } from "@material-ui/icons"
import { makeStyles } from "@material-ui/core"

import DiscipleAPI from "api/disciples"
import { participantStateOptions } from "participants/constants"
import ExportDialog from "participants/ExportDialog"

const useStyles = makeStyles({
  exportButton: {
    textDecoration: "none",
  },
})

const getDisciples = async (course, lesson) => {
  if (course) {
    return (await DiscipleAPI.forCourse(course)).results
  }
  if (lesson) {
    return (await DiscipleAPI.forLesson(lesson)).results
  }
  return []
}

const getLessons = async (dataProvider, course, lesson) => {
  if (course) {
    const { data } = await dataProvider.getList("lessons", {
      pagination: { page: 1, perPage: 100 },
      sort: { field: "number", order: "ASC" },
      filter: { course },
    })
    return data
  }
  if (lesson) {
    const lessonData = await dataProvider.getOne("lessons", { id: lesson })
    return [lessonData.data]
  }
  return []
}

const formatExportData = (participants, disciples, lessons) => {
  return participants.map((participant, index) => {
    const disciple = disciples.find((e) => e.id === participant.disciple)
    const lesson = lessons.find((e) => e.id === participant.lesson)
    return {
      number: index + 1,
      disciple: `${disciple.firstName} ${disciple.lastName}`,
      email: disciple.email,
      lesson: lesson.title,
      participatedAt: new Date(participant.participatedAt).toLocaleString(
        "ru-RU",
        {
          year: "numeric",
          month: "short",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
        }
      ),
      state: participantStateOptions[participant.state],
    }
  })
}

const ExportButton = (props) => {
  const { showExportDialog, setExportData, dataLoaded } = props
  const classes = useStyles()
  const { filter, filterValues, currentSort } = useListContext(props)
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const handleClick = useCallback(
    async (_) => {
      const disciples = await getDisciples(
        filterValues.course,
        filterValues.lesson
      )
      const lessons = await getLessons(
        dataProvider,
        filterValues.course,
        filterValues.lesson
      )
      dataProvider
        .getList("participants", {
          sort: currentSort,
          filter: filter ? { ...filterValues, ...filter } : filterValues,
          pagination: { page: 1, perPage: 300 },
        })
        .then(({ data }) => {
          setExportData(formatExportData(data, disciples, lessons))
          showExportDialog()
        })
        .catch((error) => {
          console.error(error)
          notify("ra.notification.http_error", "warning")
        })
    },
    [
      currentSort,
      dataProvider,
      filter,
      filterValues,
      notify,
      showExportDialog,
      setExportData,
    ]
  )

  return (
    <Button
      label="Экспорт"
      onClick={dataLoaded ? showExportDialog : handleClick}
      className={classes.exportButton}
    >
      <GetApp />
    </Button>
  )
}

const ParticipantListActions = ({ showExportDialog, setExportData, dataLoaded }) => (
  <TopToolbar>
    <FilterButton />
    <ExportButton
      showExportDialog={showExportDialog}
      setExportData={setExportData}
  dataLoaded={dataLoaded}
    />
  </TopToolbar>
)

const participantListFilters = [
  <ReferenceInput
    label="Занятие"
    source="lesson"
    reference="lessons"
    perPage={100}
  >
    <SelectInput optionText="title" />
  </ReferenceInput>,
  <ReferenceInput
    label="Курс"
    source="course"
    reference="courses"
    perPage={100}
  >
    <SelectInput optionText="title" />
  </ReferenceInput>,
]

export default function ParticipantList(props) {
  const [exportData, setExportData] = useState([])
  const [openExportDialog, setOpenExportDialog] = useState(false)
  return (
    <>
      <List
        {...props}
        filters={participantListFilters}
        actions={
          <ParticipantListActions
            showExportDialog={() => setOpenExportDialog(true)}
            setExportData={setExportData}
            dataLoaded={exportData.length > 0}
          />
        }
      >
        <Datagrid size="medium">
          <ReferenceField
            reference="disciples"
            source="disciple"
            label="Имя"
            sortBy="disciple__first_name"
          >
            <FunctionField
              render={(record) => `${record.firstName} ${record.lastName}`}
            />
          </ReferenceField>
          <ReferenceField
            reference="disciples"
            source="disciple"
            label="E-mail"
            sortBy="disciple__user__email"
          >
            <EmailField label="E-mail" source="email" />
          </ReferenceField>
          <ReferenceField
            reference="lessons"
            source="lesson"
            label="Занятие"
            sortable={false}
          >
            <TextField label="Занятие" source="title" />
          </ReferenceField>
          <DateField
            label="Время создания заявки"
            source="participatedAt"
            locales={["ru"]}
            options={{
              year: "numeric",
              month: "short",
              day: "numeric",
              hour: "numeric",
              minute: "numeric",
              second: "numeric",
            }}
            sortBy="participated_at"
          />
          <SelectField
            source="state"
            label="Статус"
            choices={[
              { id: "subscribed", name: "Подписан на новости" },
              { id: "reserved", name: "Резерв" },
              { id: "waiting_list", name: "Лист ожидания" },
              { id: "approved", name: "Участник" },
            ]}
            sortBy="state"
          />
        </Datagrid>
      </List>
      <ExportDialog
        handleClose={() => setOpenExportDialog(false)}
        data={exportData}
        open={openExportDialog}
      />
    </>
  )
}
