import {
  Show,
  SimpleShowLayout,
  TextField,
  ReferenceField,
  FunctionField,
} from "react-admin"
import { convertToPlainText } from "core/utils/editor"

export default function QuotationShow(props) {
  return (
    <Show {...props} title="Цитата">
      <SimpleShowLayout>
        <ReferenceField
          reference="courses"
          source="course"
          label="Курс"
          fullWidth
        >
          <TextField source="title" />
        </ReferenceField>
        <TextField source="source" label="Источник" fullWidth />
        <FunctionField
          render={(record) => convertToPlainText(record.text)}
          label="Текст"
          fullWidth
        />
      </SimpleShowLayout>
    </Show>
  )
}
