import React from "react"
import {
  Link,
  List,
  Pagination,
  PaginationActions,
  Datagrid,
  TextField,
  NumberField,
  ReferenceInput,
  SelectInput,
  TopToolbar,
  Button,
} from "react-admin"
import { Add, Edit, HowToReg } from "@material-ui/icons"

import { PropsProxy, LinkIconButton } from "core/components"

function CreateForCourseButton({ course }) {
  return (
    <Button
      component={React.forwardRef((props, ref) => (
        <Link {...props} innerRef={ref} />
      ))}
      to={{
        pathname: "/lessons/create",
        search: `?source=${JSON.stringify({ course })}`,
      }}
      label="Создать"
    >
      <Add />
    </Button>
  )
}
function LessonListActions({ filterValues }) {
  return (
    <TopToolbar>
      <CreateForCourseButton course={filterValues.course} />
    </TopToolbar>
  )
}

export default function LessonList(props) {
  const LessonsPaginationActions = (props) => (
    <PaginationActions color="secondary" {...props} />
  )
  const LessonPagination = (props) => (
    <Pagination actions={LessonsPaginationActions} {...props} />
  )
  const lessonFilters = [
    <ReferenceInput
      label="Курс"
      source="course"
      reference="courses"
      perPage={100}
      alwaysOn
      allowEmpty={false}
      sort={{ field: "title", order: "ASC" }}
    >
      <SelectInput optionText="title" />
    </ReferenceInput>,
  ]
  return (
    <List
      {...props}
      title="Занятия"
      pagination={<LessonPagination />}
      filters={lessonFilters}
      actions={<LessonListActions />}
    >
      <Datagrid rowClick="show" size="medium">
        <NumberField source="number" textAlign="center" label="Номер" />
        <TextField source="title" label="Название" />
        <PropsProxy>
          <LinkIconButton to={(record) => `/lessons/${record.id}`}>
            <Edit fontSize="inherit" />
          </LinkIconButton>
          <LinkIconButton
            to={(record) =>
              `/participants/?filter=${JSON.stringify({ lesson: record.id })}`
            }
          >
            <HowToReg fontSize="inherit" />
          </LinkIconButton>
        </PropsProxy>
      </Datagrid>
    </List>
  )
}
