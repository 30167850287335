import { useState } from "react"
import {
  Category,
  ChatBubble,
  Email,
  FormatQuote,
  LibraryBooks,
  ListAlt,
  LocalLibrary,
  People,
  TextFields,
} from "@material-ui/icons"
import { MenuItemLink } from "react-admin"
import SubMenu from "./SubMenu"

export default function Menu() {
  const [menuItemState, setMenuItemState] = useState({
    library: true,
  })

  const handleToggle = (menu) => {
    setMenuItemState((state) => ({ ...state, [menu]: !state[menu] }))
  }

  return (
    <div>
      <MenuItemLink
        to={"/courses"}
        primaryText="Курсы"
        leftIcon={<LocalLibrary />}
      />
      <MenuItemLink
        to="/disciples"
        primaryText="Ученики"
        leftIcon={<People />}
      />
      <MenuItemLink
        to={"/feedback"}
        primaryText="Отзывы"
        leftIcon={<ChatBubble />}
      />
      <MenuItemLink
        to={"/quotations"}
        primaryText="Цитаты"
        leftIcon={<FormatQuote />}
      />
      <MenuItemLink
        to={"/common-mailouts"}
        primaryText="Рассылки"
        leftIcon={<Email />}
      />
      <MenuItemLink
        to={"/pages-content"}
        primaryText="Содержимое страниц"
        leftIcon={<TextFields />}
      />
      <SubMenu
        handleToggle={() => handleToggle("library")}
        isOpen={menuItemState["library"]}
        name="Библиотека"
        icon={<LibraryBooks />}
        dense={false}
      >
        <MenuItemLink
          to={"/library-categories"}
          primaryText="Категории"
          leftIcon={<Category />}
        />
        <MenuItemLink
          to={"/library-items"}
          primaryText="Записи"
          leftIcon={<ListAlt />}
        />
      </SubMenu>
    </div>
  )
}
