import { useState } from "react"
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core"
import { Add, Cancel } from "@material-ui/icons"
import {
  Button,
  FormWithRedirect,
  required,
  SaveButton,
  TextInput,
  useCreate,
  useNotify,
} from "react-admin"
import { useForm } from "react-final-form"
import React from "react"

function LibraryCatetoryQuickCreateButton({ name }) {
  const [showDialog, setShowDialog] = useState(false)
  const [create, { loading }] = useCreate("library-categories")
  const notify = useNotify()
  const form = useForm()

  const handleClick = () => {
    setShowDialog(true)
  }

  const handleCloseClick = () => {
    setShowDialog(false)
  }

  const handleSubmit = async (values) => {
    create(
      { payload: { data: { name, ...values } } },
      {
        onSuccess: ({ data }) => {
          setShowDialog(false)
          form.change("categories", [
            ...(form.getFieldState("categories").value || []),
            data.id,
          ])
        },
        onFailure: ({ error }) => {
          notify(error.message, "error")
        },
      }
    )
  }

  return (
    <>
      <Button onClick={handleClick} label="Добавить категорию">
        <Add />
      </Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label="Create library category"
      >
        <DialogTitle>Новая категория</DialogTitle>
        <FormWithRedirect
          resource="library-categories"
          save={handleSubmit}
          render={({ handleSubmitWithRedirect, pristine, saving }) => (
            <>
              <DialogContent>
                <TextInput
                  source="name"
                  validate={required()}
                  fullWidth
                  label="Название"
                />
              </DialogContent>
              <DialogActions>
                <Button
                  label="Отменить"
                  onClick={handleCloseClick}
                  disabled={loading}
                >
                  <Cancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={handleSubmitWithRedirect}
                  pristine={pristine}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  )
}

export default LibraryCatetoryQuickCreateButton
