import copy from "copy-to-clipboard"
import {
  Button,
  FunctionField,
  NumberField,
  Show,
  SelectField,
  SimpleShowLayout,
  TextField,
  TopToolbar,
  Link,
  ListButton,
  ReferenceField,
  EditButton,
  useNotify,
} from "react-admin"
import { FileCopy, Email } from "@material-ui/icons"

import LessonsAPI from "api/lessons"
import { convertToHtml } from "core/utils/editor"

function createMarkup(html) {
  return { __html: html }
}

function LinkToRelatedParticipants({ record }) {
  return record ? (
    <ListButton
      color="primary"
      component={Link}
      to={{
        pathname: "/participants",
        search: `filter=${JSON.stringify({ lesson: record.id })}`,
      }}
      label="Участники"
    />
  ) : null
}

function LessonMailoutLink({ record }) {
  return record ? (
    <Button
      component={Link}
      to={`/lessons/${record.id}/mailout`}
      label="Рассылка"
    >
      <Email />
    </Button>
  ) : null
}

function CopyEmailButton({ record }) {
  const notify = useNotify()
  const handleClick = (event) => {
    event.preventDefault()
    LessonsAPI.mailoutEmails(record.id).then(({ emails }) => {
      copy(emails.join(",") || "")
      notify("lessons.emails.copied", "info", { count: (emails || []).length })
    })
  }
  return record ? (
    <Button onClick={handleClick} label="Скопировать email адреса">
      <FileCopy />
    </Button>
  ) : null
}

function LessonShowActions({ data }) {
  return (
    <TopToolbar>
      <LinkToRelatedParticipants record={data} />
      <EditButton record={data} />
      <LessonMailoutLink record={data} />
      <CopyEmailButton record={data} />
    </TopToolbar>
  )
}

export default function LessonShow(props) {
  return (
    <Show {...props} title="Занятие" actions={<LessonShowActions />}>
      <SimpleShowLayout>
        <ReferenceField
          source="course"
          reference="courses"
          label="Курс"
          fullWidth
        >
          <TextField source="title" />
        </ReferenceField>
        <TextField source="title" label="Название" fullWidth />
        <NumberField
          source="number"
          label="Номер"
          fullWidth
          emptyText="&ndash;"
        />
        <FunctionField
          render={({ content }) => {
            try {
              return (
                <div
                  dangerouslySetInnerHTML={createMarkup(convertToHtml(content))}
                />
              )
            } catch (error) {
              return <div>{content}</div>
            }
          }}
          label="Описание"
          emptyText="&ndash;"
        />
        <TextField source="duration" label="Продолжительность" fullWidth />
        <SelectField
          source="state"
          label="Статус"
          fullWidth
          choices={[
            { id: "published", name: "Опубликована" },
            { id: "draft", name: "Черновик" },
          ]}
        />
      </SimpleShowLayout>
    </Show>
  )
}
