import { AppBar as RAAppBar } from "react-admin"
import { Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
  title: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  spacer: {
    flex: 1,
  },
}))

export default function AppBar(props) {
  const classes = useStyles()
  return (
    <RAAppBar {...props} color="primary">
      <Typography
        variant="h6"
        color="inherit"
        className={classes.title}
        id="react-admin-title"
      />
      <span className={classes.spacer} />
    </RAAppBar>
  )
}
