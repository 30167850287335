import { Checkbox, Paper } from "@material-ui/core"
import { useEffect, useRef, useState } from "react"
import {
  AutocompleteInput,
  Create,
  Error,
  FormDataConsumer,
  Loading,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  Toolbar,
  fetchStart,
  fetchEnd,
  required,
  useDataProvider,
  useNotify,
  useRedirect,
} from "react-admin"
import { useForm } from "react-final-form"
import { useDispatch } from "react-redux"
import { CircularProgress } from "@material-ui/core"
import { Save } from "@material-ui/icons"
import { makeStyles } from "@material-ui/styles"

import ParticipantAPI from "api/participants"
import { STATE_APPROVED } from "participants/constants"
import { ToolbarButton } from "core/components"

const useStyles = makeStyles({
  hidden: {
    display: "none",
  },
  content: {
    display: "inline-block",
    padding: 10,
  },
  counter: {
    display: "inline-block",
    padding: 10,
    width: 20,
  },
  listItem: {
    borderBottom: "1px solid #e6e6e6",

    "&:last-child": {
      border: "none",
    },
  },
  list: {
    listStyleType: "none",
    padding: 0,
  },
})

const transformParticipantData = ({ lessons, disciple }) => {
  const transformed = []
  lessons.forEach((lesson) => {
    transformed.push({
      disciple,
      lesson,
      state: STATE_APPROVED,
    })
  })
  return transformed
}

const CreateButton = ({ lessons }) => {
  const form = useForm()
  const data = {
    ...form.getState().values,
    lessons,
  }
  const dispatch = useDispatch()
  const redirect = useRedirect()
  const notify = useNotify()
  const [loading, setLoading] = useState(false)
  const handleClick = (event) => {
    event.preventDefault()
    setLoading(true)
    dispatch(fetchStart())
    ParticipantAPI.create(transformParticipantData(data))
      .then(() => {
        notify("participants.creation.success")
        redirect("/courses")
      })
      .catch(() => {
        notify("participants.creation.error", "error")
      })
      .finally(() => {
        setLoading(false)
        dispatch(fetchEnd())
      })
  }
  return (
    <ToolbarButton
      label="Создать"
      handleClick={handleClick}
      loading={loading}
      icon={<Save />}
    />
  )
}

const ParticipantCreateToolbar = ({ lessons, ...rest }) => {
  return (
    <Toolbar {...rest}>
      <CreateButton lessons={lessons} />
    </Toolbar>
  )
}

export default function ParticipantCreate(props) {
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const [error, setError] = useState()
  const [selectedCourse, setSelectedCourse] = useState()

  const [courses, setCourses] = useState([])
  const [coursesLoading, setCoursesLoading] = useState(false)

  useEffect(() => {
    setCoursesLoading(true)
    dataProvider
      .getList("courses", {
        pagination: { page: 1, perPage: 100 },
        sort: { field: "title", order: "ASC" },
        filter: {},
      })
      .then(({ data }) => {
        setCoursesLoading(false)
        setCourses(data)
      })
      .catch((error) => {
        setCoursesLoading(false)
        setError(error)
        notify("participants.creation.unexpectedError", "error")
      })
  }, [dataProvider, notify])

  const [lessons, setLessons] = useState([])
  const [lessonsLoading, setLessonsLoading] = useState(false)
  const [selectedLessons, setSelectedLessons] = useState([])

  useEffect(() => {
    if (selectedCourse) {
      setLessonsLoading(true)
      dataProvider
        .getList("lessons", {
          pagination: { page: 1, perPage: 100 },
          sort: { field: "number", order: "ASC" },
          filter: { course: selectedCourse },
        })
        .then(({ data }) => {
          setLessonsLoading(false)
          setLessons(data)
        })
        .catch((error) => {
          setLessonsLoading(false)
          setError(error)
          notify("participants.creation.unexpectedError", "error")
        })
    }
  }, [dataProvider, notify, selectedCourse])

  if (coursesLoading) return <Loading />
  if (error) return <Error error={error} />

  return (
    <Create
      {...props}
      transform={(data) =>
        transformParticipantData({
          ...data,
          lessons: selectedLessons,
        })
      }
    >
      <SimpleForm
        variant="standard"
        toolbar={<ParticipantCreateToolbar lessons={selectedLessons} />}
      >
        <ReferenceInput label="Ученик" source="disciple" reference="disciples">
          <SelectInput
            optionText={({ firstName, lastName }) => `${firstName}
${lastName}`}
            disabled={true}
          />
        </ReferenceInput>
        <AutocompleteInput
          source="course"
          label="Курс"
          choices={courses.map((course) => ({
            id: course.id,
            name: course.title,
          }))}
          fullWidth
          onChange={(value) => {
            setSelectedCourse(value)
            setSelectedLessons([])
          }}
          validate={required()}
        />
        <FormDataConsumer>
          {({ formData }) =>
            formData.course &&
            (lessonsLoading ? (
              <CircularProgress />
            ) : (
              <LessonsList
                lessons={lessons}
                selectedLessons={selectedLessons}
                setSelectedLessons={setSelectedLessons}
              />
            ))
          }
        </FormDataConsumer>
      </SimpleForm>
    </Create>
  )
}

function LessonsList(props) {
  const classes = useStyles()

  const { lessons, selectedLessons, setSelectedLessons } = props
  const allLessonsSelected = useRef(false)

  const handleAll = (event) => {
    if (event.target.checked) {
      setSelectedLessons(lessons.map((lesson) => lesson.id))
      allLessonsSelected.current = true
    } else {
      setSelectedLessons([])
      allLessonsSelected.current = false
    }
  }

  const handleLesson = (lessonId) => (event) => {
    if (event.target.checked) {
      setSelectedLessons([...selectedLessons, lessonId])
    } else {
      setSelectedLessons(
        selectedLessons.filter((lesson) => lesson !== lessonId)
      )
    }
  }

  return (
    <Paper>
      <ul className={classes.list}>
        <li className={classes.listItem}>
          <div className={classes.content}>
            <Checkbox
              checked={allLessonsSelected.current}
              onChange={handleAll}
              color="primary"
            />
          </div>
          <div className={classes.counter}>#</div>
          <div className={classes.content}>Название</div>
        </li>
        {lessons.map((lesson) => (
          <li key={lesson.id} className={classes.listItem}>
            <div className={classes.content}>
              <Checkbox
                checked={selectedLessons.includes(lesson.id)}
                onChange={handleLesson(lesson.id)}
                color="primary"
              />
            </div>
            <span className={classes.counter}>{lesson.number}</span>
            <span className={classes.content}>{lesson.title}</span>
          </li>
        ))}
      </ul>
    </Paper>
  )
}
