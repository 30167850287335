import LibraryCategoryCreate from "./LibraryCategoryCreate"
import LibraryCategoryEdit from "./LibraryCategoryEdit"
import LibraryCategoryList from "./LibraryCategoriesList"
import LibraryItemCreate from "./LibraryItemCreate"
import LibraryItemEdit from "./LibraryItemEdit"
import LibraryItemList from "./LibraryItemList"
import LibraryItemShow from "./LibraryItemShow"

export const libraryItemCRUD = {
  create: LibraryItemCreate,
  edit: LibraryItemEdit,
  list: LibraryItemList,
  show: LibraryItemShow,
}

export const libraryCategoryCRUD = {
  create: LibraryCategoryCreate,
  edit: LibraryCategoryEdit,
  list: LibraryCategoryList,
}
