import { fetchJsonWithAuthJWTToken } from "ra-data-django-rest-framework"

import endpoints from "api/endpoints"

class CoursesAPI {
  mailoutEmails(id, states) {
    const searchParams = new URLSearchParams()
    if (states) {
      searchParams.append("state", states.join(","))
    }
    return fetchJsonWithAuthJWTToken(
      endpoints.courseMailoutEmails(id) + `?${searchParams.toString()}`
    ).then(({ json }) => json)
  }

  list() {
    return fetchJsonWithAuthJWTToken(
      `${endpoints.coursesList()}?page_size=100`
    ).then(({ json }) => json)
  }
}

export default new CoursesAPI()
