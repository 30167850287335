import { Edit, SimpleForm, TextInput } from "react-admin"

export default function LibraryCategoryEdit(props) {
  return (
    <Edit {...props} title="Редактирование категории">
      <SimpleForm variant="standard">
        <TextInput source="name" label="Название" />
      </SimpleForm>
    </Edit>
  )
}
