import { FormatAlignJustify, HowToReg, Edit } from "@material-ui/icons"
import {
  CreateButton,
  Datagrid,
  List,
  SelectField,
  SingleFieldList,
  TextField,
  Pagination,
  PaginationActions,
  ReferenceArrayField,
  FunctionField,
  TopToolbar,
} from "react-admin"

import { LinkIconButton, PropsProxy } from "core/components"

function CreateParticipantsButton() {
  return <CreateButton basePath="/participants" label="Записать на курс" />
}

function CourseListActions() {
  return (
    <TopToolbar>
      <CreateButton basePath="/courses" label="Создать" />
      <CreateParticipantsButton />
    </TopToolbar>
  )
}

const CoursePaginationActions = (props) => (
  <PaginationActions color="secondary" {...props} />
)
const CoursePagination = (props) => (
  <Pagination actions={CoursePaginationActions} {...props} />
)
export default function CourseList(props) {
  return (
    <List
      {...props}
      actions={<CourseListActions />}
      pagination={<CoursePagination />}
    >
      <Datagrid rowClick="show" size="medium">
        <TextField source="title" label="Название" />
        <ReferenceArrayField
          label="Авторы"
          reference="authors"
          source="authors"
          sortable={false}
        >
          <SingleFieldList>
            <FunctionField
              render={(record) => `${record.firstName} ${record.lastName}`}
            />
          </SingleFieldList>
        </ReferenceArrayField>
        <SelectField
          source="state"
          label="Статус"
          choices={[
            { id: "can_be_subscribed", name: "Открыта запись" },
            { id: "draft", name: "Черновик" },
            { id: "announced", name: "Анонсирован" },
          ]}
        />
        <PropsProxy>
          <LinkIconButton to={(record) => `/courses/${record.id}`}>
            <Edit fontSize="inherit" />
          </LinkIconButton>
          <LinkIconButton
            to={(record) =>
              `/lessons/?filter=${JSON.stringify({
                course: record.id,
              })}&sort=number&order=ASC`
            }
          >
            <FormatAlignJustify fontSize="inherit" />
          </LinkIconButton>
          <LinkIconButton
            to={(record) =>
              `/participants/?filter=${JSON.stringify({
                course: record.id,
              })}`
            }
          >
            <HowToReg fontSize="inherit" />
          </LinkIconButton>
        </PropsProxy>
      </Datagrid>
    </List>
  )
}
