import { convertToRaw } from "draft-js"
import React, { useState } from "react"
import {
  Confirm,
  fetchStart,
  fetchEnd,
  FormDataConsumer,
  FormWithRedirect,
  required,
  SelectArrayInput,
  TextInput,
  Toolbar,
  useNotify,
  useRedirect,
} from "react-admin"
import { useDispatch } from "react-redux"
import { Button } from "@material-ui/core"

import CoursesApi from "api/courses"
import MailoutsApi from "api/mailouts"
import { Editor } from "core/components"
import { convertToHtml } from "core/utils/editor"
import { formatTextByNumber } from "core/utils/text"
import { participantStateOptions } from "participants/constants"

function CourseMailoutForm(props) {
  const { courseId } = props.match.params
  const [loading, setLoading] = useState(false)
  const [emails, setEmails] = useState([])
  const [dialogOpen, setDialogOpen] = useState(false)
  const redirect = useRedirect()
  const dispatch = useDispatch()
  const notify = useNotify()

  const getEmails = (event, recipientStates) => {
    event.preventDefault()
    setLoading(true)
    dispatch(fetchStart())

    CoursesApi.mailoutEmails(courseId, recipientStates)
      .then(({ emails: fetchedEmails }) => {
        setEmails(fetchedEmails)
        setDialogOpen(true)
      })
      .catch(() => {
        notify("courses.emails.fetchError", "error")
        setDialogOpen(false)
      })
      .finally(() => {
        setLoading(false)
        dispatch(fetchEnd())
      })
  }

  const handleSending = (event, data) => {
    event.preventDefault()
    setDialogOpen(false)
    dispatch(fetchStart())
    setLoading(true)
    MailoutsApi.sendEmail(data)
      .then(() => {
        notify("courses.mailouts.success")
        redirect(`/courses/${courseId}/show`)
      })
      .catch(() => {
        notify("courses.mailouts.error")
      })
      .finally(() => {
        setLoading(false)
        dispatch(fetchEnd())
      })
  }
  return (
    <FormWithRedirect
      render={() => (
        <>
          <TextInput
            source="subject"
            label="Тема"
            fullWidth
            validate={required()}
            variant="standard"
          />
          <SelectArrayInput
            source="state"
            label="Статус участников"
            choices={Object.keys(participantStateOptions).map((state) => ({
              id: state,
              name: participantStateOptions[state],
            }))}
            fullWidth
            variant="standard"
            validate={required()}
          />
          <Editor source="text" label="Текст" />
          <Toolbar>
            <FormDataConsumer>
              {({ formData }) => (
                <Button
                  type="submit"
                  variant="contained"
                  disabled={loading}
                  onClick={(e) => {
                    getEmails(e, formData.state)
                  }}
                >
                  Отправить
                </Button>
              )}
            </FormDataConsumer>
          </Toolbar>
          <FormDataConsumer>
            {({ formData }) => (
              <Confirm
                isOpen={dialogOpen}
                loading={loading}
                title=""
                content={`Вы действительно хотите сделать рассылку на ${formatTextByNumber(
                  emails.length,
                  "адрес",
                  "адресов",
                  "адреса"
                )}?`}
                onConfirm={(event) =>
                  handleSending(event, {
                    subject: formData.subject,
                    recipients: emails.join(","),
                    text: formData.text.getCurrentContent().getPlainText(),
                    html: convertToHtml(
                      JSON.stringify(
                        convertToRaw(formData.text.getCurrentContent())
                      )
                    ),
                  })
                }
                onClose={() => setDialogOpen(false)}
              />
            )}
          </FormDataConsumer>
        </>
      )}
    />
  )
}

export default CourseMailoutForm
