import { Create, SimpleForm, TextInput, required } from "react-admin"

export default function CommonMailoutCreate(props) {
  return (
    <Create {...props} title="Новый адресат">
      <SimpleForm variant="standard">
        <TextInput
          source="email"
          label="Email"
          fullWidth
          validate={required()}
        />
        <TextInput
          source="firstName"
          label="Имя"
          fullWidth
          validate={required()}
        />
        <TextInput
          source="lastName"
          label="Фамилия"
          fullWidth
          validate={required()}
        />
      </SimpleForm>
    </Create>
  )
}
