import { Button } from "react-admin"
import ReactExport from "react-export-excel"
import { Dialog, DialogContent, DialogActions } from "@material-ui/core"

function ExportDialog({ handleClose, data, open }) {
  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogContent>
        <ReactExport.ExcelFile
          element={<Button label="Скачать данные об участниках курса" />}
        >
          <ReactExport.ExcelFile.ExcelSheet data={data} name="Участники">
            <ReactExport.ExcelFile.ExcelColumn label="#" value="number" />
            <ReactExport.ExcelFile.ExcelColumn label="Имя" value="disciple" />
            <ReactExport.ExcelFile.ExcelColumn label="E-mail" value="email" />
            <ReactExport.ExcelFile.ExcelColumn label="Занятие" value="lesson" />
            <ReactExport.ExcelFile.ExcelColumn
              label="Время создания заявки"
              value="participatedAt"
            />
            <ReactExport.ExcelFile.ExcelColumn label="Статус" value="state" />
          </ReactExport.ExcelFile.ExcelSheet>
        </ReactExport.ExcelFile>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} label="Закрыть" />
      </DialogActions>
    </Dialog>
  )
}

export default ExportDialog
