import { AddCircle } from "@material-ui/icons"
import {
  EmailField,
  List,
  Pagination,
  PaginationActions,
  Datagrid,
  TextField,
  TextInput,
  TopToolbar,
} from "react-admin"

import { LinkIconButton, PropsProxy } from "core/components"

const discipleFilters = [
  <TextInput source="first_name" label="Имя" alwaysOn />,
  <TextInput source="last_name" label="Фамилия" alwaysOn />,
  <TextInput source="email" label="Email" alwaysOn />,
]

const DiscipleActions = () => <TopToolbar />

export default function DiscipleList(props) {
  const DisciplePaginationActions = (props) => (
    <PaginationActions color="secondary" {...props} />
  )
  const DisciplePagination = (props) => (
    <Pagination actions={DisciplePaginationActions} {...props} />
  )
  return (
    <List
      {...props}
      title="Ученики"
      filters={discipleFilters}
      actions={<DiscipleActions />}
      pagination={<DisciplePagination />}
    >
      <Datagrid size="medium" rowClick="show">
        <TextField source="firstName" label="Имя" sortable={false} />
        <TextField source="lastName" label="Фамилия" sortable={false} />
        <EmailField source="email" label="Email" sortable={false} />
        <PropsProxy>
          <LinkIconButton
            to={(record) =>
              `/participants/create?source=${JSON.stringify({
                disciple: record.id,
              })}`
            }
          >
            <AddCircle fontSize="inherit" />
          </LinkIconButton>
        </PropsProxy>
      </Datagrid>
    </List>
  )
}
