import {
  ChipField,
  Show,
  SimpleShowLayout,
  TextField,
  ReferenceArrayField,
  SingleFieldList,
  UrlField,
} from "react-admin"

export default function LibraryItemShow(props) {
  return (
    <Show {...props} title="Библиотека">
      <SimpleShowLayout>
        <TextField source="author" label="Автор" emptyText="&mdash;" />
        <TextField source="title" label="Название" />
        <ReferenceArrayField
          source="categories"
          reference="library-categories"
          label="Категории"
        >
          <SingleFieldList>
            <ChipField source="name" />
          </SingleFieldList>
        </ReferenceArrayField>
        <ReferenceArrayField source="courses" reference="courses" label="Курсы">
          <SingleFieldList>
            <ChipField source="title" />
          </SingleFieldList>
        </ReferenceArrayField>
        <UrlField source="externalLink" label="Ссылка" emptyText="&mdash;" />
        <UrlField
          source="libraryFile"
          label="Файл"
          download={true}
          emptyText="&mdash;"
        />
      </SimpleShowLayout>
    </Show>
  )
}
