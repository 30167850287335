import {
  Edit,
  SimpleForm,
  ReferenceInput,
  SelectInput,
  TextInput,
} from "react-admin"

import { convertToPlainText } from "core/utils/editor"

export default function QuotationEdit(props) {
  return (
    <Edit {...props} title="Редактирование цитаты">
      <SimpleForm>
        <ReferenceInput
          label="Курс"
          reference="courses"
          source="course"
          perPage={200}
          sort={{ field: "title", order: "ASC" }}
          fullWidth
          variant="standard"
        >
          <SelectInput optionText="title" />
        </ReferenceInput>
        <TextInput
          source="source"
          label="Источник"
          fullWidth
          variant="standard"
        />
        <TextInput
          source="text"
          label="Текст"
          format={(value) => convertToPlainText(value)}
          multiline
          fullWidth
          variant="standard"
        />
      </SimpleForm>
    </Edit>
  )
}
