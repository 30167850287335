import { cloneElement } from "react"
import PropTypes from "prop-types"
import { Button, CircularProgress } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"

const useStyles = makeStyles((theme) => ({
  button: {
    position: "relative",
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  icon: {
    fontSize: 18,
  },
}))

export default function ToolbarButton({ icon, loading, label, handleClick }) {
  const classes = useStyles()
  return (
    <Button
      className={classes.button}
      variant="contained"
      onClick={handleClick}
      color={loading ? "default" : "primary"}
      aria-label={label}
      disabled={loading}
    >
      {loading ? (
        <CircularProgress
          size={18}
          thickness={2}
          className={classes.leftIcon}
        />
      ) : (
        cloneElement(icon, {
          className: `${classes.leftIcon} ${classes.icon}`,
        })
      )}
      {label}
    </Button>
  )
}

ToolbarButton.propTypes = {
  icon: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
}
