import { convertToRaw } from "draft-js"
import {
  Create,
  SimpleForm,
  required,
  TextInput,
  NumberInput,
  SelectInput,
  ReferenceInput,
} from "react-admin"
import { lessonStateOptions } from "./constants"
import { Editor } from "core/components"

const redirect = (_basePath, _id, { course }) => {
  return `/lessons/?filter=${JSON.stringify({
    course,
  })}&sort=number&order=ASC`
}

export default function LessonCreate(props) {
  const transform = ({ content, ...rest }) => ({
    ...rest,
    content:
      content && content.getCurrentContent().hasText()
        ? JSON.stringify(convertToRaw(content.getCurrentContent()))
        : "",
  })
  return (
    <Create {...props} title="Новое занятие" transform={transform}>
      <SimpleForm
        variant="standard"
        redirect={redirect}
        initialValues={{ duration: 60 }}
      >
        <ReferenceInput
          source="course"
          reference="courses"
          label="Курс"
          fullWidth
          sort={{ field: "title", order: "ASC" }}
        >
          <SelectInput optionText="title" />
        </ReferenceInput>
        <TextInput
          source="title"
          label="Название"
          fullWidth
          validate={required()}
        />
        <Editor source="content" label="Описание" />
        <NumberInput
          source="duration"
          label="Продолжительность"
          fullWidth
          validate={required()}
          min={1}
        />
        <SelectInput
          source="state"
          label="Статус"
          fullWidth
          choices={Object.keys(lessonStateOptions).map((state) => ({
            id: state,
            name: lessonStateOptions[state],
          }))}
        />
        <NumberInput
          source="number"
          label="Номер"
          fullWidth
          validate={required()}
          min={1}
        />
      </SimpleForm>
    </Create>
  )
}
