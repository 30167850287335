import {
  Edit,
  SimpleForm,
  TextInput,
  ReferenceArrayInput,
  SelectArrayInput,
  FileInput,
  UrlField,
  required,
} from "react-admin"
import { convertToBase64 } from "core/utils/file"
import LibraryCategoryQuickCreate from "library/LibraryCategoryQuickCreate"

function validate(values) {
  const errors = {}
  if (!(values.externalLink || values.libraryFile)) {
    errors.externalLink = "Необходимо указать ссылку или загрузить файл"
  }
  return Object.keys(errors).length > 0 ? errors : undefined
}

function formatFile(value) {
  if (typeof value === "string") {
    // Value is null or the url string from the backend, wrap it in an object so the form input can handle it
    return { url: value }
  } else {
    // Else a new image is selected which results in a value object already having a preview link under the url key
    return value
  }
}

export default function LibraryItemEdit(props) {
  const transform = (data) =>
    convertToBase64(data.libraryFile && data.libraryFile.rawFile)
      .then((base64Image) => ({
        ...data,
        libraryFile: base64Image,
      }))
      .catch(() => ({
        ...data,
      }))
  return (
    <Edit {...props} transform={transform}>
      <SimpleForm variant="standard" validate={validate}>
        <TextInput source="author" label="Автор" fullWidth />
        <TextInput
          source="title"
          label="Название"
          fullWidth
          validate={required()}
        />
        <ReferenceArrayInput
          source="categories"
          label="Категории"
          reference="library-categories"
          perPage={100}
          sort={{ field: "name", order: "ASC" }}
          validate={required()}
          fullWidth
        >
          <SelectArrayInput optionText="name" />
        </ReferenceArrayInput>
        <LibraryCategoryQuickCreate />
        <ReferenceArrayInput
          source="courses"
          label="Курсы"
          reference="courses"
          perPage={100}
          sort={{ field: "title", order: "ASC" }}
          fullWidth
        >
          <SelectArrayInput optionText="title" />
        </ReferenceArrayInput>
        <TextInput source="externalLink" label="Ссылка" fullWidth />
        <FileInput source="libraryFile" label="Файл" format={formatFile}>
          <UrlField source="url" />
        </FileInput>
      </SimpleForm>
    </Edit>
  )
}
