export function convertToBase64(input) {
  if (input && input instanceof File) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(input)
      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)
    })
  } else {
    return new Promise((_, reject) => reject())
  }
}
