import { Edit, SimpleForm, TextInput, required } from "react-admin"

export default function CommonMailoutEdit(props) {
  return (
    <Edit {...props} title="Редактирование адресата">
      <SimpleForm variant="standard">
        <TextInput
          source="email"
          label="Email"
          fullWidth
          validate={required()}
        />
        <TextInput
          source="firstName"
          label="Имя"
          fullWidth
          validate={required()}
        />
        <TextInput
          source="lastName"
          label="Фамилия"
          fullWidth
          validate={required()}
        />
      </SimpleForm>
    </Edit>
  )
}
