export function capitalize(s) {
  return s.charAt(0).toUpperCase() + s.slice(1)
}

export const formatTextByNumber = (number, form1, form2, form3) => {
  if (number === 1.5) {
    return `${number} ${form3}`
  }
  const integerPart = Math.floor(number)
  let formattedText = ""
  if (
    (integerPart >= 5 && integerPart <= 20) ||
    (integerPart % 10 >= 5 && integerPart % 10 <= 9) ||
    integerPart % 10 === 0
  ) {
    formattedText += `${number} ${form2}`
  } else if (integerPart % 10 === 1) {
    formattedText += `${number} ${form1}`
  } else {
    formattedText += `${number} ${form3}`
  }
  return formattedText
}
