export const STATE_SUBSCRIBED = "subscribed"
export const STATE_RESERVED = "reserved"
export const STATE_WAITING_LIST = "waiting_list"
export const STATE_APPROVED = "approved"

export const participantStateOptions = {
  [STATE_SUBSCRIBED]: "подписан на новости",
  [STATE_RESERVED]: "резерв",
  [STATE_WAITING_LIST]: "лист ожидания",
  [STATE_APPROVED]: "участник",
}
