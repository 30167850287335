import FeedbackCreate from "./FeedbackCreate"
import FeedbackEdit from "./FeedbackEdit"
import FeedbackList from "./FeedbackList"
import FeedbackShow from "./FeedbackShow"

const FeedbackCRUD = {
  create: FeedbackCreate,
  edit: FeedbackEdit,
  list: FeedbackList,
  show: FeedbackShow,
}

export default FeedbackCRUD
