import { fetchJsonWithAuthJWTToken } from "ra-data-django-rest-framework"

import endpoints from "api/endpoints"

class MailoutsApi {
  async sendEmail(data) {
    const { json } = await fetchJsonWithAuthJWTToken(endpoints.sendMailout(), {
      method: "post",
      body: JSON.stringify(data),
    })
    return json
  }
}

export default new MailoutsApi()
