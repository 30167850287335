import { convertToRaw } from "draft-js"
import {
  SimpleForm,
  required,
  TextInput,
  NumberInput,
  SelectInput,
  ReferenceInput,
} from "react-admin"
import { lessonStateOptions } from "./constants"
import { Editor, TransformedEdit } from "core/components"
import { ensureValidEditorContent } from "core/utils/editor"

export default function LessonEdit(props) {
  const trasformRecord = (record) =>
    record
      ? {
          ...record,
          content: ensureValidEditorContent(record.content),
        }
      : record
  const transform = ({ content, ...rest }) => ({
    ...rest,
    content:
      content && content.getCurrentContent().hasText()
        ? JSON.stringify(convertToRaw(content.getCurrentContent()))
        : "",
  })
  return (
    <TransformedEdit
      title="Редактирование занятия"
      transformRecord={trasformRecord}
      transform={transform}
      {...props}
    >
      <SimpleForm variant="standard">
        <ReferenceInput
          source="course"
          reference="courses"
          label="Курс"
          fullWidth
        >
          <SelectInput optionText="title" />
        </ReferenceInput>
        <TextInput
          source="title"
          label="Название"
          fullWidth
          validate={required()}
        />
        <Editor source="content" label="Описание" />
        <NumberInput
          source="duration"
          label="Продолжительность"
          fullWidth
          validate={required()}
          min={1}
        />
        <SelectInput
          source="state"
          label="Статус"
          fullWidth
          choices={Object.keys(lessonStateOptions).map((state) => ({
            id: state,
            name: lessonStateOptions[state],
          }))}
        />
        <NumberInput
          source="number"
          label="Номер"
          fullWidth
          validate={required()}
          min={1}
        />
      </SimpleForm>
    </TransformedEdit>
  )
}
