import { fetchJsonWithAuthJWTToken } from "ra-data-django-rest-framework"

import endpoints from "api/endpoints"

class DisciplesAPI {
  async list() {
    const { json } = await fetchJsonWithAuthJWTToken(
      `${endpoints.disciplesList()}?page_size=3000`
    )
    return json
  }

  async forCourse(course) {
    const { json } = await fetchJsonWithAuthJWTToken(
      `${endpoints.disciplesList()}?course=${course}&page_size=3000`
    )
    return json
  }

  async forLesson(lesson) {
    const { json } = await fetchJsonWithAuthJWTToken(
      `${endpoints.disciplesList()}?lesson=${lesson}&page_size=3000`
    )
    return json
  }
}

export default new DisciplesAPI()
